import { Script } from 'gatsby'
import React from 'react'

export const wrapRootElement = ({ element }) => {
  return (
    <>
      {/* Start cookieyes banner */}
      <Script
        key="cookieyes"
        id="cookieyes"
        type="text/javascript"
        src="https://cdn-cookieyes.com/client_data/2a76f6d0d3a27f892bc573d2/script.js"
      />
      {/* End cookieyes banner */}
      {/* Start Facebook Pixel */}
      <Script id="fb-pixel" strategy="afterInteractive">
        {`
        !function(f,b,e,v,n,t,s){
          if(f.fbq)return;
          n=f.fbq=function(){
            n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)
          };
          if(!f._fbq) f._fbq=n;
          n.push=n;
          n.loaded=!0;
          n.version='2.0';
          n.queue=[];
          t=b.createElement(e);
          t.async=!0;
          t.src=v;
          s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)
        }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '334434440823900');
        fbq('track', 'PageView');
      `}
      </Script>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=334434440823900&ev=PageView&noscript=1"
          alt="Facebook Pixel"
        />
      </noscript>
      {/* End Facebook Pixel */}
      {element}
    </>
  )
}
