import './src/styles/global.css'

declare global {
  interface Window {
    _hsq: unknown[]
  }
}

export const onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    await import('intersection-observer')
  }
}

export const onRouteUpdate = ({ location }) => {
  if (typeof window !== 'undefined') {
    window._hsq = window._hsq || []
    window._hsq.push(['setPath', location.pathname])
    window._hsq.push(['trackPageView'])
  }
}
export { wrapRootElement } from './wrap-root-element'
